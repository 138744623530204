import {
  Grid,
  makeStyles,
  TextField,
  //Box
} from '@material-ui/core';
import { ReturnTranslation } from './translation.component';
import i18next from 'i18next';
import { isConditionalExpression } from 'typescript';

export const InputCoordinates = (props: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        spacing: 8,
      },
    },
  }));
  const classes = useStyles();
  const size = 'small';

  let lang = i18next.language;
  //console.log(lang);

  let Xlabel = 'Latitude';
  let Ylabel = 'Longitude';
  /*if (lang === 'en'){
    Xlabel = 'X-coord'
  }
  if (lang === 'ru'){
    Xlabel = 'X-координата'
  }*/
  //console.log(Xlabel);

  return (
    <form className={classes.root} autoComplete='off'>
      <ReturnTranslation kei='add_area_coordinates' variant='subtitle1' gtbt={true} />
      <Grid container spacing={0} justify='space-around'>
        <Grid item xs={12}>
          <ReturnTranslation kei='upleft_corner' variant='subtitle1' gtbt={true} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            required
            id='up_left_x'
            label={Xlabel}
            type='number'
            variant='outlined'
            size={size}
            disabled={Boolean(props.isExample) || !Boolean(props.fullReady)}
            onChange={props.handleUpperLeftXChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            required
            id='up_left_y'
            label={Ylabel}
            type='number'
            variant='outlined'
            size={size}
            disabled={Boolean(props.isExample) || !Boolean(props.fullReady)}
            onChange={props.handleUpperLeftYChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ReturnTranslation kei='btright_corner' variant='subtitle1' gtbt={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id='down_right_x'
            label={Xlabel}
            type='number'
            variant='outlined'
            size={size}
            disabled={Boolean(props.isExample) || !Boolean(props.fullReady)}
            onChange={props.handleLowerRightXChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id='down_right_y'
            label={Ylabel}
            type='number'
            variant='outlined'
            size={size}
            disabled={Boolean(props.isExample) || !Boolean(props.fullReady)}
            onChange={props.handleLowerRightYChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
