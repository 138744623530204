import React, { Suspense, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import { ReturnTranslation } from './components/translation.component';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    color: 'rgb(255,255,255)',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      boxShadow: 'none',
    },
  },
});

function SwitchComponent() {
  const [t, i18n] = useTranslation('common');
  let languages = ['en', 'ru'];
  const [count, setCount] = useState(1);
  const classes = useStyles();
  return (
    <div>
      <ButtonGroup size='small'>
        <Button
          className={classes.button}
          onClick={() => {
            setCount(count + 1);
            //console.log(count  % 2);
            i18n.changeLanguage(languages[count % 2]);
          }}>
          {languages[count % 2]}
        </Button>
      </ButtonGroup>
    </div>
  );
}

export class Head extends React.Component {
  componentDidMount() {
    //console.log(document.getElementById('header2').offsetWidth);
    if (document.getElementById('header2').offsetWidth < 400) {
      document.getElementById('our_logo').style.display = 'none';
    }
  }

  render() {
    return (
      <Suspense fallback='loading'>
        <header id='header'>
          <Grid container spacing={0} justify='center'>
            <Grid item xs={12} md={12} lg={10}>
              <div id='header2'>
                <div id='our_logo'>
                  <img src='logo.png' width='100%' height='100%' alt='lorem' />
                </div>
                <div id='name'>
                  <Box id='name2'>
                    <ReturnTranslation kei='scangeo' variant='h3' gtbt={false} />
                    <ReturnTranslation kei='description' variant='subtitle1' gtbt={false} />
                  </Box>
                </div>
                <Box id='aboutus'>
                  <Box style={{ float: 'right', textAlign: 'right' }}>
                    <a href='mailto:hi@futuremed.systems' style={{ color: 'rgb(255,255,255)' }}>
                      <ReturnTranslation kei='contactus' variant='subtitle1' gtbt={false} />
                    </a>
                    <Box id='lang_switcher' mt={1}>
                      <SwitchComponent />
                    </Box>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </header>
      </Suspense>
    );
  }
}
