import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReturnTranslation } from './translation.component';

export const MobileProgressBar = (props: {
  pictureProcessingStatus: number;
  processedPictureBase64: string | undefined;
  resultWasDrawn: Boolean;
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      height: 5,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  if (props.pictureProcessingStatus === -1) {
    return null;
  } else if (
    props.pictureProcessingStatus !== -1 &&
    !Boolean(props.resultWasDrawn) &&
    document.getElementById('pictureBox').offsetWidth < 550
  ) {
    return (
      <Box className={classes.root} mt={1}>
        <ReturnTranslation kei='waiting' variant='h6' gtbt={true} />
        <LinearProgress />
      </Box>
    );
  } else return null;
};
