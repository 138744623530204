import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CloudUpload } from '@material-ui/icons';
import React from 'react';
import { ReturnTranslation } from './translation.component';

export const SendToProcessingButton = (props: any) => {
  //console.log(props.isExample);
  return (
    <Box mt={1}>
      <Button
        variant='contained'
        color='primary'
        disabled={!Boolean(props.fullReady)}
        onClick={() => props.handlePostButtonClick()}
        endIcon={<CloudUpload />}
        size='medium'
        fullWidth={true}>
        <ReturnTranslation kei='process_with_ai' variant='subtitle2' gtbt={false} />
      </Button>
    </Box>
  );
};
