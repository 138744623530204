import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontSize: 14,
  },
});
theme = responsiveFontSizes(theme, { factor: 4, breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'] });
//theme.typography.fontFamily = 'fontsource-roboto';
/*
theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:300px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.3rem',
  },
};
/*

theme.typography.subtitle1 = {
  fontSize: '0.8rem',
  '@media (min-width:300px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};*/

export function ReturnTranslation(props) {
  const [t, i18n] = useTranslation('common');
  //console.log(props.kei);
  //console.log(t(props.kei));

  return (
    <ThemeProvider theme={theme}>
      <Typography variant={props.variant} gutterBottom={props.gtbt}>
        {t(props.kei)}
      </Typography>
    </ThemeProvider>
  );
}
