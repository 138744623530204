import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Photo } from '@material-ui/icons';
import React from 'react';
import { ReturnTranslation } from './translation.component';

export const UploadButton = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        //margin: theme.spacing(1),
        spacing: 8,
        width: 500,
      },
    },
    input: {
      display: 'none',
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <input
        accept='image/*'
        className={classes.input}
        id='contained-button-file'
        type='file'
        disabled={!Boolean(props.fullReady)}
        onChange={(event) => props.onChange(event)}
      />
      <label htmlFor='contained-button-file'>
        <Box component='div' mb={1}>
          <Button
            variant='contained'
            color='primary'
            disabled={!Boolean(props.fullReady)}
            component='span'
            size='medium'
            startIcon={<Photo />}
            fullWidth={true}>
            <ReturnTranslation kei='upload_photo' variant='subtitle2' gtbt={false} />
          </Button>
        </Box>
      </label>
    </div>
  );
};
