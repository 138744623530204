import { Box, Button, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import { ReturnTranslation } from './translation.component';

function downloadBase64File(base64Data, fileName) {
  const linkSource = base64Data;
  const downloadLink = document.createElement('a');
  //console.log(linkSource);
  downloadLink.href = linkSource;
  //console.log("fileName: ", fileName);
  //console.log("result" + fileName.slice(fileName.indexOf(".")));
  downloadLink.download = 'result.png';
  downloadLink.click();
}

export const ProgressBar = (props: {
  pictureProcessingStatus: number;
  processedPictureBase64: string | undefined;
  imageTag: string;
  resultWasDrawn: Boolean;
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      height: 5,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  if (props.pictureProcessingStatus === -1) {
    return null;
  } else if (props.pictureProcessingStatus !== -1 && !Boolean(props.resultWasDrawn)) {
    return (
      <Box className={classes.root} mt={1}>
        <ReturnTranslation kei='waiting' variant='h6' gtbt={true} />
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <Box>
        <Box display='flex' justifyContent='center' mt={1}>
          <ReturnTranslation kei='done' variant='h6' gtbt={true} />
        </Box>
        <Box justifyContent='center' mt={0}>
          <Button
            variant='contained'
            color='primary'
            size='medium'
            startIcon={<Save />}
            onClick={() => downloadBase64File(props.processedPictureBase64, props.imageTag)}>
            <ReturnTranslation kei='save' variant='subtitle2' gtbt={false} />
          </Button>
        </Box>
      </Box>
    );
};
