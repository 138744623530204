import React, { Suspense } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme } from '@material-ui/core/styles';
import 'fontsource-roboto';
import { ProgressBar, AlertDialog, ControlledAccordions } from './components';
import i18next from 'i18next';

import './index.css';
import { AiService } from './services';

const examples_descriptions = [
    '',
    'example1.jpg', //пример2
    'example2.jpg', //пример5
    'example3.jpg', //пример6
    'example4.jpg', //пример3
    'example5.jpg', //пример4
    'example6.jpg', //пример1
];

const upleftXs = [null, 54.945434, 54.945144, 54.939528, 54.963005, 54.957301, 54.957578];
const upleftYs = [null, 20.435782, 20.422257, 20.503441, 20.480089, 20.464628, 20.483326];
const downrightXs = [null, 54.939263, 54.941069, 54.934866, 54.957637, 54.952026, 54.951837];
const downrightYs = [null, 20.447101, 20.4296, 20.511773, 20.489779, 20.474173, 20.493429];

// const examples_ids = [
//     '',
//     'b280b556-a55e-44b0-89bf-09501666ef78',
//     'abda246f-ce51-4a25-8b4f-0463f0547265',
//     '1f8c4aca-48f9-464e-a119-1beaf96b5f1b',
//     '52bf57cd-e9da-4257-b3e0-02deab474cb7',
//     'de7d6e00-e323-4133-b5d0-2da2ab2a1a61',
//     '3c36a0f5-dab4-4ebf-b5be-34f190c9f062',
// ];
const examples_ids = [
    '',
    '85e056b1-e9a1-4185-a9b0-0c3199e3a88d',
    '7f66dee4-1905-4153-9029-27af9d1c47d2',
    'def9f2af-8522-4e78-8e97-ad957fecb5c0',
    'd4f8cdcf-cd01-4b51-847f-344ed9d57c3d',
    'da334a99-64d2-4afd-b117-4c45d2671292',
    '6ebf31ca-91da-40d7-b9e4-28e56aea3333'
];

const size_trsh = 7340032;
const dim_trsh = 2000;
const standart_canvas_size = 600;

let theme = createMuiTheme();
theme.typography.h3 = {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
};
theme.typography.subtitle1 = {
    fontSize: '0.8rem',
    '@media (min-width:600px)': {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.1rem',
    },
};

function scale_coord_to_pix(value, min_coord, max_coord, dim_in_pix) {
    var relative_coord_value = (value - min_coord) / (max_coord - min_coord); // 0 - 1
    var relative_pix_value = Math.floor(relative_coord_value * dim_in_pix + 0.5);

    return relative_pix_value;
}

function new_scale_coord_to_pix(value, min_coord, max_coord, dim_in_pix) {
    var longitude_shift_const = 90;

    if (max_coord - min_coord > 180 && Math.sign(min_coord) * Math.sign(max_coord) < 0) {
        var new_x1 = 180 + (180 + min_coord) - longitude_shift_const;
        var new_x2 = max_coord - longitude_shift_const;
        min_coord = Math.min(new_x1, new_x2);
        max_coord = Math.max(new_x1, new_x2);

        if (value >= 0) {
            value = value - longitude_shift_const;
        } else {
            value = 360 + value - longitude_shift_const;
        }
    }

    var relative_coord_value = (value - min_coord) / (max_coord - min_coord); // # 0 - 1
    var relative_pix_value = Math.floor(relative_coord_value * dim_in_pix + 0.5);

    return relative_pix_value;
}

const style = {
    height: 780,
};

export class App extends React.Component {
    componentDidMount() {
        document.getElementById('jsheader').scrollIntoView({ behavior: 'smooth' });
        //console.log("lang: ", i18next.language);
    }

    /*componentWillUnmount() {
      document.getElementById("header").scrollIntoView({ behavior: 'smooth' });
    }*/

    constructor(props) {
        super(props);
        this.inputCoordinatesRef = React.createRef();
        this.postButtonRef = React.createRef();
        this.imageSectionRef = React.createRef();
        this.state = {
            imgWidth: 0,
            imgHeight: 0,
            imageTag: null,
            imageFileItself: null,
            imageFileExt: null,
            base64picture: null,
            upperLeftX: upleftXs[0],
            upperLeftY: upleftYs[0],
            lowerRightX: downrightXs[0],
            lowerRightY: downrightYs[0],
            requestId: null,
            pictureProcessingStatus: -1,
            processedPictureBase64: null,
            fullReady: true,
            resultWasDrawn: false,
            isExample: 0,
            anyError: '',
            lang: i18next.language,
        };
        this.handleImageLoading = this.handleImageLoading.bind(this);
        this.handleUpperLeftXChange = this.handleUpperLeftXChange.bind(this);
        this.handleUpperLeftYChange = this.handleUpperLeftYChange.bind(this);
        this.handleLowerRightXChange = this.handleLowerRightXChange.bind(this);
        this.handleLowerRightYChange = this.handleLowerRightYChange.bind(this);
        this.handlePostButtonClick = this.handlePostButtonClick.bind(this);
        this.tryGetRequest = this.tryGetRequest.bind(this);
        this.handleStopTryingGet = this.handleStopTryingGet.bind(this);
        this.handleExampleChanging = this.handleExampleChanging.bind(this);
        this.getProcessedImage = this.getProcessedImage.bind(this);
    }

    createDrawResultImage = (imageDescription, arrayOfHouses, props, func) => {
        var img = new Image();
        let processedImageBase64 = null;

        var canvas = document.getElementById('canvas_fake');
        var ctx = canvas.getContext('2d');

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            //console.log(arrayOfHouses.length);
            ctx.fillStyle = 'rgb(255,255,0)';
            for (let i = 0; i < arrayOfHouses.length; i++) {
                const house = arrayOfHouses[i].Points;
                //console.log(house.length);

                /*let start_x = scale_coord_to_pix(
                  house[0].X,
                  Math.min(props.upperLeftX, props.lowerRightX),
                  Math.max(props.upperLeftX, props.lowerRightX),
                  img.width
                );*/
                let image_x = new_scale_coord_to_pix(
                    house[0].Y,
                    Math.min(props.upperLeftY, props.lowerRightY),
                    Math.max(props.upperLeftY, props.lowerRightY),
                    img.width
                );
                /*let start_y = scale_coord_to_pix(
                  house[0].Y,
                  Math.min(props.upperLeftY, props.lowerRightY),
                  Math.max(props.upperLeftY, props.lowerRightY),
                  img.height
                );*/
                let image_y = new_scale_coord_to_pix(
                    house[0].X,
                    props.upperLeftX,
                    props.lowerRightX,
                    img.height
                );
                ctx.beginPath();
                ctx.moveTo(image_x, image_y);

                for (let j = 1; j < house.length; j++) {
                    /*let x = scale_coord_to_pix(
                      house[j].X,
                      Math.min(props.upperLeftX, props.lowerRightX),
                      Math.max(props.upperLeftX, props.lowerRightX),
                      img.width
                    );*/
                    let image_x = new_scale_coord_to_pix(
                        house[j].Y,
                        Math.min(props.upperLeftY, props.lowerRightY),
                        Math.max(props.upperLeftY, props.lowerRightY),
                        img.width
                    );
                    /*let y = scale_coord_to_pix(
                      house[j].Y,
                      Math.min(props.upperLeftY, props.lowerRightY),
                      Math.max(props.upperLeftY, props.lowerRightY),
                      img.height
                    );*/
                    let image_y = new_scale_coord_to_pix(
                        house[j].X,
                        props.upperLeftX,
                        props.lowerRightX,
                        img.height
                    );
                    ctx.lineTo(image_x, image_y);
                }
                ctx.fill();
            }
            processedImageBase64 = canvas.toDataURL(props.imageTag);
            canvas.style.display = 'none';
            //console.log(processedImageBase64);
            this.setState({
                processedPictureBase64: processedImageBase64,
                fullReady: true,
                resultWasDrawn: true,
            });
            this.drawPreviewResultImage(this.state.processedPictureBase64);
        };
        img.src = imageDescription;
    };

    drawChosenImage = (imageDescription, props, isExample, redraw) => {
        var canvas_original = document.getElementById('canvas_original');
        var ctx_original = canvas_original.getContext('2d');
        var chosen_img_preview = new Image();

        chosen_img_preview.onload = () => {
            if (chosen_img_preview.width > dim_trsh || chosen_img_preview.height > dim_trsh) {
                //alert("Image width or height mustn't exceed " + dim_trsh.toString() + ".px");

                this.setState({ anyError: 'error_big_image_dim' });
                return;
            }
            //console.log(redraw);
            if (!Boolean(isExample)) {
                if (!redraw) {
                    document.getElementById('up_left_x').value = null;
                    document.getElementById('up_left_y').value = null;
                    document.getElementById('down_right_x').value = null;
                    document.getElementById('down_right_y').value = null;
                }
            }

            const adaptive_canvas_size = Math.min(
                standart_canvas_size,
                Math.floor(document.getElementById('pictureBox').offsetWidth * 0.9)
            );
            canvas_original.width = adaptive_canvas_size;
            canvas_original.height = adaptive_canvas_size;

            ctx_original.clearRect(0, 0, canvas_original.width, canvas_original.height);
            var wrh = chosen_img_preview.width / chosen_img_preview.height;
            var newWidth = canvas_original.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas_original.height) {
                newHeight = canvas_original.height;
                newWidth = newHeight * wrh;
            }
            canvas_original.width = newWidth;
            ctx_original.drawImage(chosen_img_preview, 0, 0, newWidth, newHeight);

            if (!redraw) {
                this.setState({
                    base64picture: imageDescription,
                    isExample: isExample,
                    pictureProcessingStatus: -1,
                    processedPictureBase64: null,
                    resultWasDrawn: false,
                });
            }
        };
        chosen_img_preview.src = imageDescription;
    };

    drawPreviewResultImage(imageDescription) {
        var canvas = document.getElementById('canvas_original');
        var ctx = canvas.getContext('2d');
        var img = new Image();

        img.onload = () => {
            const adaptive_canvas_size = Math.min(
                standart_canvas_size,
                Math.floor(document.getElementById('pictureBox').offsetWidth * 0.9)
            );
            canvas.width = adaptive_canvas_size;
            canvas.height = adaptive_canvas_size;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            var wrh = img.width / img.height;
            var newWidth = canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas.height) {
                newHeight = canvas.height;
                newWidth = newHeight * wrh;
            }
            canvas.width = newWidth;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            this.imageSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        };
        img.src = imageDescription;
    }

    handleImageLoading(event) {
        //alert("image loading");
        if (!Boolean(this.state.fullReady)) {
            console.log(!Boolean(this.state.fullReady));
            return;
        }

        if (event.target.files.length > 0) {
            //console.log("size: ", event.target.files[0].size);
            if (event.target.files[0].size > size_trsh) {
                //alert("image size must be less 7Mb");
                this.setState({ anyError: 'error_big_image_size' });
                return;
            }
            //console.log('ext: ', event.target.files[0].type);
            if (!event.target.files[0].type.includes('image')) {
                this.setState({ anyError: 'error_wrong_file_type' });
                return;
            }
            if (event.target.files[0].type.includes('tiff')) {
                this.setState({ anyError: 'error_tiff' });
                return;
            }
            //this.inputCoordinatesRef.current.scrollIntoView({ behavior: 'smooth' });
            this.setState({
                imageTag: event.target.files[0].type,
                imageFileItself: event.target.files[0],
                imageFileExt: (event.target.files[0].type).split('/')[1]
            });
            //console.log("ext: ", event.target.files[0].type.includes("image"));

            const reader = new FileReader();
            reader.onload = this.handleImageReaderLoaded.bind(this);
            reader.readAsBinaryString(event.target.files[0]);
            event.target.value = null;

            //this.inputCoordinatesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleImageReaderLoaded(readerEvt) {
        //alert("image reading");

        let binaryString = readerEvt.target.result;
        const base64 = btoa(binaryString);
        //console.log(this.state.base64picture);
        const imageDescription = 'data:' + this.state.imageTag + ';base64,' + base64;
        //console.log(imageDescription);
        this.drawChosenImage(imageDescription, this.state, 0, false);
    }

    handleUpperLeftXChange(event) {
        this.setState({ upperLeftX: Number(event.target.value) });
    }

    handleUpperLeftYChange(event) {
        this.setState({ upperLeftY: Number(event.target.value) });
    }

    handleLowerRightXChange(event) {
        this.setState({ lowerRightX: Number(event.target.value) });
    }

    handleLowerRightYChange(event) {
        this.setState({ lowerRightY: Number(event.target.value) });
    }

    validateInputs = () => {
        if (!Boolean(this.state.base64picture)) {
            this.setState({ anyError: 'error_no_image' });
            return false;
        }
        if (!Boolean(document.getElementById('up_left_x').value)) {
            this.setState({ anyError: 'error_no_upleft_coord' });
            return false;
        }
        if (!Boolean(document.getElementById('up_left_y').value)) {
            this.setState({ anyError: 'error_no_upleft_coord' });
            return false;
        }
        if (!Boolean(document.getElementById('down_right_x').value)) {
            this.setState({ anyError: 'error_no_lowright_coord' });
            return false;
        }
        if (!Boolean(document.getElementById('down_right_y').value)) {
            this.setState({ anyError: 'error_no_lowright_coord' });
            return false;
        }
        if (
            document.getElementById('up_left_x').value === document.getElementById('down_right_x').value
        ) {
            this.setState({ anyError: 'error_zero_width' });
            return false;
        }
        if (
            document.getElementById('up_left_y').value === document.getElementById('down_right_y').value
        ) {
            this.setState({ anyError: 'error_zero_height' });
            return false;
        }
        return true;
    };

    async handlePostButtonClick() {
        if (!Boolean(this.state.fullReady)) {
            //console.log(!Boolean(this.state.fullReady));
            return;
        }

        if (!this.validateInputs()) {
            return;
        }

        const redraw = true;
        this.drawChosenImage(this.state.base64picture, this.state, this.state.isExample, redraw);

        this.setState({
            upperLeftX: Number(document.getElementById('up_left_x').value),
            upperLeftY: Number(document.getElementById('up_left_y').value),
            lowerRightX: Number(document.getElementById('down_right_x').value),
            lowerRightY: Number(document.getElementById('down_right_y').value),
            fullReady: false,
            pictureProcessingStatus: -1,
            resultWasDrawn: false,
        });

        //console.log('New upper left {', this.state.upperLeftX, ', ', this.state.upperLeftY, ' }');
        //console.log('New lower right {', this.state.lowerRightX, ', ', this.state.lowerRightY, ' }');
        //console.log(this.state.isExample);

        //document.getElementById("pictureBox").scrollIntoView({ behavior: 'smooth' });

        await this.imageSectionRef.current.scrollIntoView({ behavior: 'smooth' });

        if (this.state.isExample === 0) {
            const requestParams = {
                "IsForTrain": false,
                // Image: this.state.base64picture,
                ImageFormat: this.state.imageFileExt,
                LeftTopPoint: {
                    X: this.state.upperLeftX,
                    Y: this.state.upperLeftY
                },
                RightBottomPoint: {
                    X: this.state.lowerRightX,
                    Y: this.state.lowerRightY,
                },
                MustReturnConstructions: true,
                MustReturnCars: false,
            };

            //console.log("postRequestString: ", postRequestString);
            this.setState({
                // requestId: requestId,
                pictureProcessingStatus: 0,
            });
            const presignedResponse = await AiService.askPresignedURLtoUpload(requestParams);

            const presignedResponseStatus = await presignedResponse.status;
            console.log('postResponseStatus: ', presignedResponseStatus);
            if (presignedResponseStatus === 200) {
                const presignedResponseJson = await presignedResponse.json();
                // console.log('postResponseJson: ', postResponseJson);
                const requestId = presignedResponseJson.guid;
                // console.log('requestId: ', requestId);
                const uploadingURL = presignedResponseJson.presigned.url;
                const uploadFields = presignedResponseJson.presigned.fields;
                // console.log('Now ready to upload to:\n', uploadingURL, '\nwith credentials:\n', uploadFields);
                this.setState({
                    requestId: requestId,
                    // pictureProcessingStatus: 0,
                });

                const uploadingResponse = await AiService.uploadToBucket(uploadingURL, uploadFields, this.state.imageFileItself);
                const uploadingResponseStatus = await uploadingResponse.status;
                console.log('uploadingResponseStatus: ', uploadingResponseStatus);
                // console.log('postResponseRequestId: ', postResponseRequestId);
                if (uploadingResponseStatus === 204) {
                    this.processTryingGet = setInterval(this.tryGetRequest, 5000, requestId);
                }
                else {
                    this.setState({
                        anyError: 'error_server_request',
                        pictureProcessingStatus: -1,
                        fullReady: true,
                    });
                }
            } else {
                this.setState({
                    anyError: 'error_server_request',
                    pictureProcessingStatus: -1,
                    fullReady: true,
                });
            }
        } else {
            this.setState({ pictureProcessingStatus: 2 });
            const postResponseRequestId = examples_ids[this.state.isExample];
            this.processTryingGet = setInterval(this.tryGetRequest, 5000, postResponseRequestId);
        }
    }

    async tryGetRequest(requestId) {
        //console.log(process.env.NODE_ENV);

        //console.log("getRequestString: ", getRequestString);
        const getResponse = await AiService.getImageProcessingResult(requestId);

        const getResponseStatus = await getResponse.status;
        //console.log("getResponseStatus: ", getResponseStatus);
        if (getResponseStatus === 200) {
            const getResponseJson = await getResponse.json();
            const getResponsePictureProcessingStatus = getResponseJson.Status;
            this.setState({
                pictureProcessingStatus: getResponsePictureProcessingStatus,
            });
            console.log('get status: ', getResponsePictureProcessingStatus);
            if (this.state.pictureProcessingStatus === 3) {
                console.log('Got result. Stop trying GET');
                clearInterval(this.processTryingGet);
                const imageDescription = this.state.base64picture;
                //console.log(getResponseJson)
                const housesArray = getResponseJson.Result;
                //console.log("housesArray: ", housesArray);
                this.createDrawResultImage(
                    imageDescription,
                    housesArray,
                    this.state,
                    this.getProcessedImage()
                );
            }
            if (this.state.pictureProcessingStatus === 4) {
                clearInterval(this.processTryingGet);
                this.setState({
                    anyError: 'error_status_four',
                    pictureProcessingStatus: -1,
                    fullReady: true,
                });
            }
        } else {
            this.setState({
                anyError: 'error_server_request',
                pictureProcessingStatus: -1,
                fullReady: true,
            });
            clearInterval(this.processTryingGet);
        }
    }

    handleStopTryingGet() {
        console.log('handleStopTryingGet');
        clearInterval(this.processTryingGet);
    }

    handleExampleChanging = (number) => {
        this.setState({ isExample: number });
        document.getElementById('up_left_x').value = upleftXs[number];
        document.getElementById('up_left_y').value = upleftYs[number];
        document.getElementById('down_right_x').value = downrightXs[number];
        document.getElementById('down_right_y').value = downrightYs[number];

        let imageDescription;

        imageDescription = examples_descriptions[number];

        document.getElementById('letsgo').scrollIntoView({ behavior: 'smooth' });
        //this.inputCoordinatesRef.current.scrollIntoView({ behavior: 'smooth' });
        //sleep(2000);
        //this.postButtonRef.current.scrollIntoView({ behavior: 'smooth' });

        this.setState({
            upperLeftX: Number(document.getElementById('up_left_x').value),
            upperLeftY: Number(document.getElementById('up_left_y').value),
            lowerRightX: Number(document.getElementById('down_right_x').value),
            lowerRightY: Number(document.getElementById('down_right_y').value),
            base64picture: imageDescription,
            imageTag: '.jpg',
            pictureProcessingStatus: -1,
            processedPictureBase64: null,
        });

        this.drawChosenImage(imageDescription, this.state, number);
    };

    handleAccordeonTabChange = (number) => {
        this.setState({ isExample: number });
        document.getElementById('up_left_x').value = upleftXs[number];
        document.getElementById('up_left_y').value = upleftYs[number];
        document.getElementById('down_right_x').value = downrightXs[number];
        document.getElementById('down_right_y').value = downrightYs[number];
        //console.log("tab main: ", number);
        if (number !== 0) {
            //console.log('example: ', this.state.isExample);
            const imageDescription = examples_descriptions[number];

            this.setState({
                upperLeftX: Number(document.getElementById('up_left_x').value),
                upperLeftY: Number(document.getElementById('up_left_y').value),
                lowerRightX: Number(document.getElementById('down_right_x').value),
                lowerRightY: Number(document.getElementById('down_right_y').value),
                base64picture: imageDescription,
                imageTag: '.jpg',
                pictureProcessingStatus: -1,
                processedPictureBase64: null,
            });
            this.drawChosenImage(imageDescription, this.state, number);
        } else {
            var canvas_original = document.getElementById('canvas_original');
            var ctx_original = canvas_original.getContext('2d');
            ctx_original.clearRect(0, 0, canvas_original.width, canvas_original.height);
            //console.log('not example');
            this.setState({
                base64picture: null,
                imageTag: null,
                pictureProcessingStatus: -1,
                processedPictureBase64: null,
            });
        }
    };

    handleCloseError = () => {
        this.setState({ anyError: '' });
    };

    async getProcessedImage(imageDescription) {
        await this.setState({ processedPictureBase64: imageDescription });
    }

    render() {
        return (
            <Suspense fallback='loading'>
                <div
                    style={{
                        flexGrow: 1,
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}>
                    <Grid container spacing={0} justify='center'>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <Box m={2} id='accordeon'>
                                <Paper elevation={3} style={style}>
                                    <Box height='100%'>
                                        <Box height='100%'>
                                            <ControlledAccordions
                                                handleAccordeonTabChange={this.handleAccordeonTabChange}
                                                handleExampleChanging={this.handleExampleChanging}
                                                handleImageLoading={this.handleImageLoading}
                                                fullReady={this.state.fullReady}
                                                isExample={this.state.isExample}
                                                handleUpperLeftXChange={this.handleUpperLeftXChange}
                                                handleUpperLeftYChange={this.handleUpperLeftYChange}
                                                handleLowerRightXChange={this.handleLowerRightXChange}
                                                handleLowerRightYChange={this.handleLowerRightYChange}
                                                inputCoordinatesRef={this.inputCoordinatesRef}
                                                handlePostButtonClick={this.handlePostButtonClick}
                                                base64picture={this.state.base64picture}
                                                pictureProcessingStatus={this.state.pictureProcessingStatus}
                                                processedPictureBase64={this.state.processedPictureBase64}
                                                resultWasDrawn={this.state.resultWasDrawn}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={7} lg={6} ref={this.imageSectionRef}>
                            <Box m={2} id='pictureBox'>
                                <Paper elevation={3} style={style}>
                                    <Box p={3}>
                                        <Box>
                                            <Box display='flex' p={1} justifyContent='center'>
                                                <AlertDialog
                                                    errorkey={this.state.anyError}
                                                    handleClose={() => this.handleCloseError()}
                                                />
                                                <canvas id='canvas_original' width={0} height={0} />
                                                <canvas id='canvas_fake' width={0} height={0} />
                                            </Box>
                                        </Box>
                                        <Box display='flex' justifyContent='center'>
                                            <ProgressBar
                                                pictureProcessingStatus={this.state.pictureProcessingStatus}
                                                processedPictureBase64={this.state.processedPictureBase64}
                                                imageTag={this.state.imageTag}
                                                resultWasDrawn={this.state.resultWasDrawn}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Suspense>
        );
    }
}
