const API_URL = process.env.REACT_APP_API_URL || '';
const API_KEY = process.env.REACT_APP_NN_API_KEY;

// TODO : add type for body
const askPresignedURLtoUpload = async (body: any) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
        body: JSON.stringify(body),
    };
    const postRequestString = API_URL + 'requests';

    return await fetch(postRequestString, requestOptions);
};

const getImageProcessingResult = async (requestId: string) => {
    const getRequestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
        },
    };

    const getRequestString = API_URL + 'requests/' + requestId;
    return await fetch(getRequestString, getRequestOptions);
};

const uploadToBucket = async (uploadingURL: string, uploadFields: any, imageFileItself: any) => {
    var form_data = new FormData();

    for (var key in uploadFields) {
        form_data.append(key, uploadFields[key]);
    }
    form_data.append('file', imageFileItself);
    const uploadOptions = {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        body: form_data
    };

    return await fetch(uploadingURL, uploadOptions)
};

export const AiService = {
    askPresignedURLtoUpload,
    uploadToBucket,
    getImageProcessingResult,
};
