import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import MuiAccordion from '@material-ui/core/Accordion';
//import Accordion from '@material-ui/core/Accordion';
import { Box, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { UploadButton, InputCoordinates, SendToProcessingButton, MobileProgressBar } from '.';

import { ReturnTranslation } from './translation.component';

const useStyles = makeStyles((theme) => ({
  root: {
    //background: 'rgb(0, 255, 0)',
    width: '100%',
  },
  rootExpanded: {
    //background: "blue",
    flexGrow: 1,
  },
  content: {
    //background: 'linear-gradient(180deg, rgba(195,195,195,1) 0%, rgba(204,204,204,1) 20%, rgba(196,196,196,1) 80%, rgba(181,181,181,1) 100%)',
    borderRadius: 5,
    border: '1px solid rgba(0, 0, 0, .125)',
    //color: 'rgb(255, 255, 255)',
  },
  details: {
    //background: 'rgb(255, 0, 0)',
    alignItems: 'center',
  },
  column1: {
    flexBasis: '33.33333%',
  },
  column2: {
    flexBasis: '100%',
  },
}));

const examples_descriptions = [
  '',
  'example1.jpg', //пример2
  'example2.jpg', //пример5
  'example3.jpg', //пример6
  'example4.jpg', //пример3
  'example5.jpg', //пример4
  'example6.jpg',
]; //пример1

export const ControlledAccordions = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(panel);
    //console.log("tab: ", panel);
    props.handleAccordeonTabChange(0);
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Accordion
        className={Boolean(expanded === 1) ? classes.rootExpanded : classes.root}
        disabled={!Boolean(props.fullReady)}
        expanded={Boolean(expanded === 1)}
        onChange={handleChange(1)}>
        <AccordionSummary
          className={classes.content}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'>
          <ReturnTranslation kei='examples' variant='h6' gtbt={false} />
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Box className={classes.column2} mb={-3} ml={1} mt={1}>
            <Box>
              <ReturnTranslation kei='clickexample' variant='subtitle1' gtbt={false} />
            </Box>
            <Box id='letsgo'>
              <ReturnTranslation kei='letsgo' variant='subtitle1' gtbt={true} />
            </Box>
          </Box>
        </AccordionDetails>
        <AccordionDetails className={classes.details}>
          <Box className={classes.column1}>
            <Box>
              <Box m={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(1)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[1]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>

              <Box m={1} mt={2}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(2)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[2]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.column1}>
            <Box>
              <Box m={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(3)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[3]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>
              <Box m={1} mt={2}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(4)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[4]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.column1}>
            <Box>
              <Box m={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(5)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[5]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>
              <Box m={1} mt={2}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.handleExampleChanging(6)}
                  disabled={!Boolean(props.fullReady)}>
                  <Box mt={1}>
                    <img src={examples_descriptions[6]} width='100%' height='100%' alt='lorem' />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
        <AccordionDetails className={classes.details}>
          <Box ml={1} mt={-1} mb={1} mr={1} className={classes.column2}>
            <SendToProcessingButton
              handlePostButtonClick={() => props.handlePostButtonClick()}
              isExample={props.isExample}
              base64picture={props.base64picture}
              fullReady={props.fullReady}
            />
            <Box p={0} display='flex' justifyContent='center'>
              <MobileProgressBar
                pictureProcessingStatus={props.pictureProcessingStatus}
                processedPictureBase64={props.processedPictureBase64}
                resultWasDrawn={props.resultWasDrawn}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={Boolean(expanded === 0) ? classes.rootExpanded : classes.root}
        disabled={!Boolean(props.fullReady)}
        expanded={Boolean(expanded === 0)}
        onChange={handleChange(0)}>
        <AccordionSummary
          className={classes.content}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel6bh-content'
          id='panel6bh-header'>
          <ReturnTranslation kei='advanced_mode' variant='h6' gtbt={false} />
        </AccordionSummary>
        <AccordionDetails>
          <Box p={1}>
            <Box>
              <ReturnTranslation kei='tryyourself' variant='subtitle1' gtbt={true} />
            </Box>
            <Box mt={3} mb={3}>
              <ReturnTranslation kei='choose_image' variant='subtitle1' gtbt={true} />
              <UploadButton
                onChange={(event) => props.handleImageLoading(event)}
                fullReady={props.fullReady}
              />
            </Box>
            <Box id='inputCoordinates'>
              <InputCoordinates
                handleUpperLeftXChange={props.handleUpperLeftXChange}
                handleUpperLeftYChange={props.handleUpperLeftYChange}
                handleLowerRightXChange={props.handleLowerRightXChange}
                handleLowerRightYChange={props.handleLowerRightYChange}
                isExample={props.isExample}
                fullReady={props.fullReady}
              />
            </Box>
            <Box mt={2}>
              <SendToProcessingButton
                handlePostButtonClick={() => props.handlePostButtonClick()}
                isExample={props.isExample}
                base64picture={props.base64picture}
                fullReady={props.fullReady}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
