import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

let theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontSize: 14,
  },
});
theme = responsiveFontSizes(theme, { factor: 4, breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'] });

function ReturnTranslation(props) {
  const [t, i18n] = useTranslation('common');
  //console.log(props.kei);
  //console.log(t(props.kei));
  return (
    <ThemeProvider theme={theme}>
      <Typography variant={props.variant} gutterBottom={props.gtbt}>
        {t(props.kei)}
      </Typography>
    </ThemeProvider>
  );
}

export const AlertDialog = (props: any) => {
  return (
    <div>
      <Dialog
        open={Boolean(props.errorkey)}
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box>
            <ReturnTranslation kei={props.errorkey} variant='h6' gtbt={false} />
          </Box>
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
